import { GTMContext } from '@/context/GTMContext';
import {
  gtmEvent_contact_email_address,
  gtmEvent_go_to_contact,
  gtmEvent_go_to_games,
  gtmEvent_go_to_privacy_policy,
  gtmEvent_go_to_terms_of_service,
  gtmEvent_how_it_works,
} from '@/utils/GTMFunctions';
import Link from 'next/link';
import { useContext } from 'react';

// Footer component
const Footer = (props) => {
  // destructuring props

  // context hooks
  const { setDataLayer } = useContext(GTMContext);
  // state

  // effect

  // queries

  // other variables/functions/handlers

  // render
  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__content-logo">
          <img src="/footer-logo.png" alt="logo" />
        </div>
        <div className="footer__content-links">
          <div className="footer__content-links-item">
            <Link
              onClick={() => setDataLayer(gtmEvent_go_to_contact())}
              href="mailto:agent@unicornparty.com"
              className="footer__content-links-item_link"
            >
              Contact
            </Link>
            <Link
              onClick={() => setDataLayer(gtmEvent_go_to_privacy_policy())}
              href="/privacy-policy"
              className="footer__content-links-item_link"
            >
              Privacy Policy
            </Link>
            <Link
              onClick={() => setDataLayer(gtmEvent_go_to_terms_of_service())}
              href="/terms-of-service"
              className="footer__content-links-item_link"
            >
              Terms of Service
            </Link>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="footer__bottom-content">
          <div className="footer__bottom-content-item">Copyright 2023 •</div>
          <Link
            onClick={() => setDataLayer(gtmEvent_contact_email_address())}
            href="mailto:agent@unicornparty.com"
            className="footer__bottom-content-item"
          >
            agent@unicornparty.com
          </Link>
        </div>
      </div>
    </div>
  );
};

// export
export default Footer;
